
.his-bg{
    background-image: url(/images/sub/about/history/his-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.his-year{
    position: relative;
}
.his-year::after{
    content: "";
    display: block;
    position: absolute;
    width: 70px;
    height: 2px;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(var(--primary-gra));
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .his-year::after{
        width: 100px;
        height: 3px;
    }
}
@media (min-width:1200px){
}

